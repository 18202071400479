export const checksums = {
  "content_de": "zviXqoEGWW",
  "content_en": "R23Bgayb71",
  "content_es": "TNI6tlQVDF",
  "blog_de": "RvOHuXfIrd",
  "blog_en": "Kb8yGrwPwq",
  "blog_es": "uQPXYWGQng",
  "careers_de": "Rmoywjr5Sk",
  "careers_en": "K8tvz3c0Wl",
  "careers_es": "6ZVEeRPwPU",
  "team": "eyWAJAUqnS"
}

export const tables = {
  "content_de": "_content_content_de",
  "content_en": "_content_content_en",
  "content_es": "_content_content_es",
  "blog_de": "_content_blog_de",
  "blog_en": "_content_blog_en",
  "blog_es": "_content_blog_es",
  "careers_de": "_content_careers_de",
  "careers_en": "_content_careers_en",
  "careers_es": "_content_careers_es",
  "team": "_content_team",
  "info": "_content_info"
}

export default {
  "content_de": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "keywords": "json"
    }
  },
  "content_en": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "keywords": "json"
    }
  },
  "content_es": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "keywords": "json"
    }
  },
  "blog_de": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "date": "date",
      "tags": "json",
      "image": "string",
      "author": "json"
    }
  },
  "blog_en": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "date": "date",
      "tags": "json",
      "image": "string",
      "author": "json"
    }
  },
  "blog_es": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "date": "date",
      "tags": "json",
      "image": "string",
      "author": "json"
    }
  },
  "careers_de": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "type": "string",
      "place": "string",
      "image": "string",
      "applyUrl": "string",
      "draft": "boolean"
    }
  },
  "careers_en": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "type": "string",
      "place": "string",
      "image": "string",
      "applyUrl": "string",
      "draft": "boolean"
    }
  },
  "careers_es": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "type": "string",
      "place": "string",
      "image": "string",
      "applyUrl": "string",
      "draft": "boolean"
    }
  },
  "team": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "teamMembers": "json"
    }
  },
  "info": {
    "fields": {}
  }
}